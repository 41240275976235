.mod_height img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
#contact .basic-2{
    padding-top: 0px;
    padding-bottom: 0px;
}

#contact .social-container .fab:hover,
#contact .social-container .fas:hover {
    color: #a16fcf;
}
.basic-2{
    padding-top: 2.5rem;
}

.mod_height {
    height: 400px;
    background-image: url(../../assets/images/contacto.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: auto 130%;
}