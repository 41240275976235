/* Description: Master CSS file */

/*****************************************
Table Of Contents:
- General Styles
- Navigation
- Header
- Services
- Details 1
- Details 2
- Features
- Testimonials
- Customers
- Invitation
- Contact
- Footer
- Copyright
- Back To Top Button
- Extra Pages
- Media Queries
******************************************/

/*****************************************
Colors:
- Backgrounds - light gray #fcfafb
- Buttons, icons - blue #a16fcf
- Buttons, icons - red #ef473d
- Headings text - black #223150
- Body text - dark gray #5e6576
******************************************/


/**************************/
/*     General Styles     */
/**************************/
body,
html {
    width: 100%;
	height: 100%;
}

body, p {
	color: #5e6576; 
	font: 400 1rem/1.625rem "Open Sans", sans-serif;
}

h1 {
	color: #223150;
	font-weight: 700;
	font-size: 2.5rem;
	line-height: 3.25rem;
	letter-spacing: -0.4px;
}

h2 {
	color: #223150;
	font-weight: 700;
	font-size: 2rem;
	line-height: 2.5rem;
	letter-spacing: -0.4px;
}

h3 {
	color: #223150;
	font-weight: 700;
	font-size: 1.75rem;
	line-height: 2.25rem;
	letter-spacing: -0.2px;
}

h4 {
	color: #223150;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 2rem;
	letter-spacing: -0.2px;
}

h5 {
	color: #223150;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.625rem;
}

h6 {
	color: #223150;
	font-weight: 700;
	font-size: 1rem;
	line-height: 1.375rem;
}

.p-large {
	font-size: 1.125rem;
	line-height: 1.75rem;
}

.p-small {
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.testimonial-text {
	font-style: italic;
}

.testimonial-author {
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.li-space-lg li {
	margin-bottom: 0.5rem;
}

a {
	color: #5e6576;
	text-decoration: underline;
}

a:hover {
	color: #5e6576;
	text-decoration: underline;
}

.no-line {
	text-decoration: none;
}

.no-line:hover {
	text-decoration: none;
}

.read-more {
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.read-more .fas {
	margin-left: 0.625rem;
	font-size: 1rem;
	vertical-align: -8%;
}

.blue {
	color: #a16fcf;
}

.bg-gray {
	background-color: #fcfafb;
}

.section-title {
	margin-bottom: 0.5rem;
	color: #ef473d;
	font-weight: 600;
	font-size: 0.875rem;
}

.btn-solid-reg {
	display: inline-block;
	padding: 1.375rem 2.25rem 1.375rem 2.25rem;
	border: 1px solid #a16fcf;
	border-radius: 30px;
	background-color: #a16fcf;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-solid-reg:hover {
	background-color: transparent;
	color: #a16fcf; /* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

.btn-solid-lg {
	display: inline-block;
	padding: 1.625rem 2.625rem 1.625rem 2.625rem;
	border: 1px solid #a16fcf;
	border-radius: 30px;
	background-color: #a16fcf;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-solid-lg:hover {
	background-color: transparent;
	color: #a16fcf; /* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

.btn-solid-sm {
	display: inline-block;
	padding: 1rem 1.5rem 1rem 1.5rem;
	border: 1px solid #a16fcf;
	border-radius: 30px;
	background-color: #a16fcf;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-solid-sm:hover {
	background-color: transparent;
	color: #a16fcf; /* needs to stay here because of the color property of a tag */
	text-decoration: none;
}

.btn-outline-reg {
	display: inline-block;
	padding: 1.375rem 2.25rem 1.375rem 2.25rem;
	border: 1px solid #ef473d;
	border-radius: 30px;
	background-color: transparent;
	color: #ef473d;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-reg:hover {
	background-color: #ef473d;
	color: #ffffff;
	text-decoration: none;
}

.btn-outline-lg {
	display: inline-block;
	padding: 1.625rem 2.625rem 1.625rem 2.625rem;
	border: 1px solid #ef473d;
	border-radius: 30px;
	background-color: transparent;
	color: #ef473d;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-lg:hover {
	background-color: #ef473d;
	color: #ffffff;
	text-decoration: none;
}

.btn-outline-sm {
	display: inline-block;
	padding: 1rem 1.5rem 1rem 1.5rem;
	border: 1px solid #ef473d;
	border-radius: 30px;
	background-color: transparent;
	color: #ef473d;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	text-decoration: none;
	transition: all 0.2s;
}

.btn-outline-sm:hover {
	background-color: #ef473d;
	color: #ffffff;
	text-decoration: none;
}

.form-group {
	position: relative;
	margin-bottom: 1.25rem;
}

.form-control-input,
.form-control-textarea {
	width: 100%;
	padding-top: 0.875rem;
	padding-bottom: 0.875rem;
	padding-left: 1.5rem;
	border: 1px solid #cbcbd1;
	border-radius: 8px;
	background-color: #ffffff;
	font-size: 0.875rem;
	line-height: 1.5rem;
	-webkit-appearance: none; /* removes inner shadow on form inputs on ios safari */
}

.form-control-textarea {
	display: block;
	height: 14rem; /* used instead of html rows to normalize height between Chrome and IE/FF */
}

.form-control-input:focus,
.form-control-textarea:focus {
	border: 1px solid #a1a1a1;
	outline: none; /* Removes blue border on focus */
}

.form-control-input:hover,
.form-control-textarea:hover {
	border: 1px solid #a1a1a1;
}

.form-control-submit-button {
	display: inline-block;
	width: 100%;
	height: 3.25rem;
	border: 1px solid #a16fcf;
	border-radius: 30px;
	background-color: #a16fcf;
	color: #ffffff;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0;
	cursor: pointer;
	transition: all 0.2s;
}

.form-control-submit-button:hover {
	border: 1px solid #a16fcf;
	background-color: transparent;
	color: #a16fcf;
}

.text-justify {
  text-align: justify;
}

.container_outstanding_image{
  width: 100%;
  height: 65vh;
  max-height: 500px;
}
.container_outstanding_image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-maxh {
  max-height: 400px;
}
/**********************/
/*     Navigation     */
/**********************/
.navbar {
	background-color: #fcfafb;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0.875rem;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.05);
}

.navbar .navbar-brand {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}

.navbar .logo-image img {
    width: 160px;
	  height: auto;
}

.navbar .logo-text {
	color: #223150;
	font-weight: 700;
	font-size: 1.875rem;
	line-height: 1rem;
	text-decoration: none;
}

.offcanvas-collapse {
	position: fixed;
	top: 3.25rem; /* adjusts the height between the top of the page and the offcanvas menu */
	bottom: 0;
	left: 100%;
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	overflow-y: auto;
	visibility: hidden;
	background-color: #fcfafb;
	transition: visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
	transition: transform .3s ease-in-out, visibility .3s ease-in-out;
	transition: transform .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

.offcanvas-collapse.open {
	visibility: visible;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
}

.navbar .navbar-nav {
	margin-top: 0.75rem;
	margin-bottom: 0.5rem;
}

.navbar .nav-item .nav-link {
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	color: #5e6576;
	text-decoration: none;
	transition: all 0.2s ease;
}

.navbar .nav-item.dropdown.show .nav-link,
.navbar .nav-item .nav-link:hover,
.navbar .nav-item .nav-link.active {
	color: #a16fcf;
}

/* Dropdown Menu */
.navbar .dropdown .dropdown-menu {
	animation: fadeDropdown 0.2s; /* required for the fade animation */
}

@keyframes fadeDropdown {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
	}
}

.navbar .dropdown-menu {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	border: none;
	background-color: #fcfafb;
}

.navbar .dropdown-item {
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	color: #5e6576;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 0.875rem;
	text-decoration: none;
}

.navbar .dropdown-item:hover {
	background-color: #fcfafb;
	color: #a16fcf;
}

.navbar .dropdown-divider {
	width: 100%;
	height: 1px;
	margin: 0.5rem auto 0.5rem auto;
	border: none;
	background-color: #d1d1d1;
}
/* end of dropdown menu */

.navbar .navbar-toggler {
	padding: 0;
	border: none;
	font-size: 1.25rem;
}

.navbar .navbar-toggler:focus {
	box-shadow: none;
}


/******************/
/*     Header     */
/******************/
.header {
	padding-top: 8rem;
	padding-bottom: 4em;
	background: url('./assets/images/header-background.jpg') center center no-repeat;
	background-size: cover;
	text-align: center;
}

.header .h1-large {
	margin-bottom: 1.75rem;
}

.header .p-large {
	margin-bottom: 2.25rem;
}

.header .image-container {
	margin-top: 5rem;
}


/********************/
/*     Services     */
/********************/
.cards-1 {
	padding-top: 5rem;
	padding-bottom: 2.25em;
}

.cards-1 h2 {
	margin-bottom: 3.5rem;
}

.cards-1 .card {
	margin-bottom: 5rem;
	border: none;
	background-color: transparent;
}

.cards-1 .card-icon {
	width: 100%;
	height: 200px;
	padding-bottom: 1.875rem;
	margin-bottom: 1.875rem;
	border-radius: 8px;
	background-color: #ddedfb;
	text-align: center;
}

.cards-1 .card-icon img {
	width: 100%;
  height: 100%;
  object-fit: contain;
}

.cards-1 .card-icon .fas,
.cards-1 .card-icon .far {
	color: #a16fcf;
	font-size: 2rem;
	line-height: 66px;
}

.cards-1 .card-icon.red {
	background-color: #fbe4e4;
}

.cards-1 .card-icon.red .fas,
.cards-1 .card-icon.red .far {
	color: #ef473d;
}

.cards-1 .card-icon.green {
	background-color: #e0f4ea;
}

.cards-1 .card-icon.green .fas,
.cards-1 .card-icon.green .far {
	color: #16c66d;
}

.cards-1 .card-body {
	padding: 0;
}

.cards-1 .card-title {
	margin-bottom: 1.125rem;
}

.cards-1 .card-body p {
	margin-bottom: 1.125rem;
  text-align: justify;
}

.cards-1 .read-more {
	color: #a16fcf;
}

.cards-1 .read-more .fas {
	color: #a16fcf;
}


/*********************/
/*     Details 1     */
/*********************/
.basic-1 {
	padding-top: 2.25rem;
	padding-bottom: 4.75rem;
	background: linear-gradient(rgb(252, 250, 251), rgb(255, 255, 255));
}

.basic-1 .image-container {
	margin-bottom: 4rem;
}

.basic-1 h2 {
	margin-bottom: 1.75rem;
}

.basic-1 p {
	margin-bottom: 2rem;
}


/*********************/
/*     Details 2     */
/*********************/
.basic-2 {
	padding-top: 4.5rem;
	padding-bottom: 4.5rem;
	/* background: url('../images/details-2-background.jpg') center center no-repeat; */
	background-size: cover;
}

.basic-2 .text-container {
	margin-bottom: 4rem;
}

.basic-2 h2 {
	margin-bottom: 1.75rem;
}

.basic-2 p {
	margin-bottom: 1.5rem;
}


/********************/
/*     Features     */
/********************/
.accordion-1 {
	padding-top: 9.25rem;
	padding-bottom: 9.75rem;
	/* background: url('../images/features-background.jpg') center center no-repeat; */
	background-size: cover;
}

.accordion-1 .h2-heading {
	margin-bottom: 1rem;
	color: #ffffff;
	text-align: center;
}

.accordion-1 .p-heading {
	margin-bottom: 4rem;
	color: #ffffff;
	text-align: center;
}

.accordion-1 .accordion {
	margin-bottom: 5rem;
}

.accordion-1 .accordion-item {
	margin-bottom: 2.5rem;
	border: none;
	border-radius: 0;
	background-color: transparent;
}

.accordion-1 .accordion-item:last-of-type {
	margin-bottom: 0;
}

.accordion-1 .accordion-icon {
	position: absolute;
	width: 44px;
	height: 44px;
	border-radius: 4px;
	text-align: center;
	background-color: #14be9f;
}

.accordion-1 .accordion-icon.blue {
	background-color: #1d79fb;
}

.accordion-1 .accordion-icon.purple {
	background-color: #dc63ff;
}

.accordion-1 .accordion-icon.orange {
	background-color: #ff6c02;
}

.accordion-1 .accordion-icon .fas {
	color: #ffffff;
	font-size: 1.25rem;
	line-height: 44px;
}

.accordion-1 .accordion-header {
	margin-left: 4.125rem;
	padding: 0.5rem 0 0.5rem 0;
	border: none;
	background-color: transparent;
}

.accordion-1 .accordion-header button {
	padding: 0;
	border: none;
	background-color: transparent;
	color: #ffffff;
	box-shadow: none;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.625rem;
	letter-spacing: -0.4px;
	text-align: left;
}

.accordion-1 .accordion-header button:after {
	display: none;
}

.accordion-1 .accordion-body {
	margin-left: 4.125rem;
	padding: 0.375rem 0 0 0;
	color: #ffffff;
}


/************************/
/*     Testimonials     */
/************************/
.cards-2 {
	padding-top: 9.5rem;
	padding-bottom: 2.25rem;
	text-align: center;
}

.cards-2 .h2-heading {
	margin-bottom: 3.75rem;
}

.cards-2 .card {
	position: relative;
	margin-bottom: 5rem;
	border-radius: 10px;
	box-shadow: 0 4px 20px -1px rgba(0, 0, 0, 0.1);
}

.cards-2 .card .quotes {
	position: absolute;
	top: -15px;
	right: 0;
	left: 0;
	width: 48px;
	margin-right: auto;
	margin-left: auto;
}

.cards-2 .card-body {
	padding: 3.5rem 2rem 3.125rem 2rem;
}

.cards-2 .testimonial-author {
	margin-bottom: 0.125rem;
	color: #223150;
}

.cards-2 .occupation {
	font-size: 0.875rem;
	line-height: 1.5rem;
}

.cards-2 .card .gradient-floor {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 10px;
	width: 100%;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.cards-2 .card .gradient-floor.red-to-blue {
	background: linear-gradient(to right, #ef3d91, #a16fcf);
}

.cards-2 .card .gradient-floor.blue-to-purple {
	background: linear-gradient(to right, #a16fcf, #637eff);
}

.cards-2 .card .gradient-floor.purple-to-green {
	background: linear-gradient(to right, #637eff, #16c66d);
}


/********************/
/*    Customers     */
/********************/
.slider-1 {
	padding-top: 2rem;
	padding-bottom: 3.75rem;
	background: linear-gradient(rgb(252, 250, 251), rgb(255, 255, 255));
	text-align: center;
}

.slider-1 h4 {
	margin-bottom: 0.25rem;
}

.slider-1 .section-divider {
	margin-bottom: 1rem;
}

.slider-1 .slider-container {
	margin-bottom: 0.5rem;
	padding: 1rem 0;
}


/**********************/
/*     Invitation     */
/**********************/
.basic-3 {
	padding-top: 4rem;
	background-size: cover;
	text-align: center;
}

.basic-3 .text-container {
	padding: 5rem 1.5rem 5.5rem 1.5rem;
	border-radius: 28px;
	/* background: url('../images/invitation-background.jpg') center center no-repeat; */
	background-size: cover;
}

.basic-3 h2 {
	margin-bottom: 1rem;
	color: #ffffff;
}

.basic-3 .p-large {
	margin-bottom: 2.75rem;
	color: #ffffff;
}

.basic-3 .btn-solid-lg {
	border-color: #16c66d;
	background-color: #16c66d;
}

.basic-3 .btn-solid-lg:hover {
	border-color: #ffffff;
	background-color: transparent;
	color: #ffffff;
}


/*******************/
/*     Contact     */
/*******************/
.form-1 {
	padding-top: 4.5rem;
	padding-bottom: 8.75rem;
}

.form-1 .text-container {
	margin-bottom: 4rem;
}

.form-1 .section-divider {
	margin-bottom: 1rem;
}

.form-1 h2 {
	margin-bottom: 1.75rem;
}

.form-1 .list-unstyled .fas {
	font-size: 0.375rem;
	line-height: 1.625rem;
}

.form-1 .list-unstyled .flex-grow-1 {
	margin-left: 0.5rem;
}


/******************/
/*     Footer     */
/******************/
.footer {
	position: relative;
	padding-top: 7.5rem;
	padding-bottom: 6rem;
	text-align: center;
}

.footer .decoration-circles {
	position: absolute;
	top: 96px;
	left: -60px;
}

.footer div[class*="col"] {  
	/* selects all elements which have classes starting with col */
	/* needed so that the absolute positioned decoration stays behind */
	position: relative;
}

.footer a {
	color: #223150;
	text-decoration: none;
}

.footer h4 {
	margin-bottom: 1.875rem;
}

.footer .fa-stack {
	width: 2em;
	margin-bottom: 1.25rem;
	margin-right: 0.375rem;
	font-size: 1.5rem;
}

.footer .fa-stack .fa-stack-1x {
    color: #a16fcf;
	transition: all 0.2s ease;
}

.footer .fa-stack .fa-stack-2x {
	color: #ffffff;
	transition: all 0.2s ease;
}

.footer .fa-stack:hover .fa-stack-1x {
	color: #ffffff;
}

.footer .fa-stack:hover .fa-stack-2x {
    color: #a16fcf;
}


/*********************/
/*     Copyright     */
/*********************/
.copyright {
	padding-top: 1.5rem;
	border-top: 1px solid #d0dae2;
	text-align: center;
}

.copyright a {
	text-decoration: none;
}

.copyright .list-unstyled,
.copyright .statement {
	opacity: 0.7;
}


/******************************/
/*     Back To Top Button     */
/******************************/
#myBtn {
	position: fixed; 
  	z-index: 99; 
	bottom: 20px; 
	right: 20px; 
	display: none; 
	width: 52px;
	height: 52px;
	border: none; 
	border-radius: 50%; 
	outline: none; 
	background-color: #44434a; 
	cursor: pointer; 
}

#myBtn:hover {
	background-color: #1d1d21;
}

#myBtn img {
	margin-bottom: 0.25rem;
	width: 18px;
}


/***********************/
/*     Extra Pages     */
/***********************/
.ex-header {
	padding-top: 7rem;
	padding-bottom: 2rem;
	background-color: #fcfafb;
}

.ex-basic-1 .list-unstyled .fas {
	font-size: 0.375rem;
	line-height: 1.625rem;
}

.ex-basic-1 .list-unstyled .flex-grow-1 {
	margin-left: 0.5rem;
}

.ex-basic-1 .text-box {
	padding: 1.25rem 1.25rem 0.5rem 1.25rem;
	background-color: #fcfafb;
}

.ex-cards-1 .card {
	border: none;
	background-color: transparent;
}

.ex-cards-1 .card .fa-stack {
	width: 2em;
	font-size: 1.125rem;
}

.ex-cards-1 .card .fa-stack-2x {
	color: #a16fcf;
}

.ex-cards-1 .card .fa-stack-1x {
	width: 2em;
	color: #ffffff;
	font-weight: 700;
	line-height: 2.125rem;
}

.ex-cards-1 .card .list-unstyled .flex-grow-1 {
	margin-left: 2.25rem;
}

.ex-cards-1 .card .list-unstyled .flex-grow-1 h5 {
	margin-top: 0.125rem;
	margin-bottom: 0.5rem;
}


/*************************/
/*     Media Queries     */
/*************************/	
/* Min-width 768px */
@media (min-width: 768px) {

	/* Header */
	.header {
		padding-top: 9rem;
		padding-bottom: 5em;
	}

	.header .h1-large {
		font-size: 3.25rem;
		line-height: 3.75rem;
	}
	/* end of header */


	/* Statistics */
	.counter .counter-cell {
		display: inline-block;
		margin-right: 1.5rem;
		margin-left: 1.5rem;
		vertical-align: top;
	}
	/* end of statistics */


	/* Extra Pages */
	.ex-basic-1 .text-box {
		padding: 1.75rem 2rem 0.875rem 2rem;
	}
	/* end of extra pages */
}
/* end of min-width 768px */


/* Min-width 992px */
@media (min-width: 992px) {
	
	/* General Styles */
	.h2-heading {
		width: 35.25rem;
		margin-right: auto;
		margin-left: auto;
	}

	.p-heading {
		width: 46rem;
		margin-right: auto;
		margin-left: auto;
	}
	/* end of general styles */


	/* Navigation */
	.navbar {
		padding-top: 1rem;
		padding-bottom: 1rem;
		transition: all 0.2s;
    background-color: #fcfafb;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 5%);
	}

	.navbar.top-nav-collapse {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		background-color: #fcfafb;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
	}

	.offcanvas-collapse {
		position: static;
		top: auto;
		bottom: auto;
		left: auto;
		width: auto;
		padding-right: 0;
		padding-left: 0;
		background-color: transparent;
		overflow-y: visible;
		visibility: visible;
	}

	.offcanvas-collapse.open {
		-webkit-transform: none;
		transform: none;
	}

	.navbar .navbar-nav {
		margin-top: 0;
		margin-bottom: 0;
	}
	
	.navbar .nav-item .nav-link {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.navbar .dropdown-menu {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.08);
	}

	.navbar .dropdown-divider {
		width: 90%;
	}

	.navbar .nav-item .btn-solid-sm {
		margin-top: 0;
		margin-left: 1rem;
	}
	/* end of navigation */


	/* Header */
	.header {
		text-align: left;
	}

	.header .text-container {
		margin-top: 4rem;
	}

	.header .image-container {
		margin-top: 0;
	}

	.header .btn-solid-lg,
	.header .btn-outline-lg {
		margin-right: 0;
		margin-left: 0;
	}
	
	.header .btn-solid-lg {
		margin-right: 0.5rem;
	}
	/* end of header */


	/* Services */
	.cards-1 .card {
		display: inline-block;
		width: 286px;
		vertical-align: top;
	}
	
	.cards-1 .card:nth-of-type(3n+2) {
		margin-right: 2rem;
		margin-left: 2rem;
	}
	/* end of services */


	/* Details 1 */
	.basic-1 .image-container {
		margin-bottom: 0;
	}

	.basic-1 .text-container {
		margin-top: 2rem;
	}
	/* end of details 1 */
	
	
	/* Details 2 */
	.basic-2 .text-container {
		margin-top: 3rem;
		margin-bottom: 0;
	}
	/* end of details 2 */


	/* Testimonials */
	.cards-2 .card {
		display: inline-block;
		width: 298px;
		vertical-align: top;
	}
	
	.cards-2 .card:nth-of-type(3n+2) {
		margin-right: 1rem;
		margin-left: 1rem;
	}
	/* end of testimonials */


	/* Invitation */
	.basic-3 .text-container {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}

	.basic-3 h2 {
		width: 42rem;
		margin-right: auto;
		margin-left: auto;
	}

	.basic-3 .p-large {
		width: 35rem;
		margin-right: auto;
		margin-left: auto;
	}
	/* end of invitation */


	/* Footer */
	.footer h4 {
		width: 46rem;
		margin-right: auto;
		margin-left: auto;
	}
	/* end of footer */


	/* Copyright */
	.copyright {
		text-align: left;
	}

	.copyright .list-unstyled li {
		display: inline-block;
		margin-right: 1rem;
	}

	.copyright .statement {
		text-align: right;
	}
	/* end of copyright */


	/* Extra Pages */
	.ex-cards-1 .card {
		display: inline-block;
		width: 296px;
		vertical-align: top;
	}

	.ex-cards-1 .card:nth-of-type(3n+2) {
		margin-right: 1rem;
		margin-left: 1rem;
	}
	/* end of extra pages */
}
/* end of min-width 992px */


/* Min-width 1200px */
@media (min-width: 1200px) {
	
	/* General Styles */
	.container {
		max-width: 1140px;
	}
	/* end of general styles */
	

	/* Header */
	.header {
		overflow: hidden;
		padding-top: 20.25rem;
		padding-bottom: 15rem;
	}

	.header .text-container {
		margin-top: 0;
	}

	.header .image-container {
		position: relative;
	}
	
	.header .image-container img {
		position: absolute;
		bottom: -520px;
		left: 60px;
		max-width: none;
	}
	/* end of header */


	/* Services */
	.cards-1 .card {
		width: 310px;
	}
	
	.cards-1 .card:nth-of-type(3n+2) {
		margin-right: 5.5rem;
		margin-left: 5.5rem;
	}
	/* end of services */


	/* Details 1 */
	.basic-1 .text-container {
		margin-top: 5rem;
		margin-left: 2.75rem;
	}
	/* end of details 1 */


	/* Details 2 */
	.basic-2 .text-container {
		margin-top: 0rem;
		margin-right: 3rem;
	}

  .basic-2 .text-container p {
		text-align: justify;
	}

	.basic-2 .image-container {
		text-align: right;
	}
	/* end of details 2 */


	/* Features */
	.accordion-1 .accordion {
		margin-bottom: 0;
	}

	.accordion-1 .image-container {
		text-align: right;
	}
	/* end of features */


	/* Testimonials */
	.cards-2 .card {
		width: 356px;
	}
	
	.cards-2 .card:nth-of-type(3n+2) {
		margin-right: 1.125rem;
		margin-left: 1.125rem;
	}
	/* end of testimonials */


	/* Invitation */
	.basic-3 .text-container {
		padding-top: 8rem;
		padding-bottom: 8.5rem;
	}
	/* end of invitation */


	/* Contact */
	.form-1 .text-container {
		margin-top: 1.5rem;
		margin-right: 2.75rem;
	}
	
	.form-1 form {
		margin-left: 2.75rem;
	}
	/* end of contact */


	/* Footer */
	.footer .decoration-circles {
		left: 0;
	}
	/* end of footer */


	/* Extra Pages */
	.ex-cards-1 .card {
		width: 336px;
	}

	.ex-cards-1 .card:nth-of-type(3n+2) {
		margin-right: 2.875rem;
		margin-left: 2.875rem;
	}
	/* end of extra pages */
}
/* end of min-width 1200px */